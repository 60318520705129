import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { logOut } from '../../Redux/action/Auth';

function SessionOutModal() {
    const dispatch = useDispatch()
    const [sessionModel, setSessionModel] = useState(true)

    const logoutAction = () => {
        dispatch(logOut())
        setSessionModel(false)
    }

    return (
        <div id="myModal" className={`modal fade cs--modal ${sessionModel ? "show" : "hide"}`} style={{ display: sessionModel ? "block" : "none" }}>
            <div className="modal-dialog modal-confirm">
                <div className="modal-content">
                    <div className="modal-header flex-column">
                    <h4 className="modal-title w-100 text-center text-warning">Utrac</h4>
                    </div>
                    <div className="modal-body">
                        <h5 style={{ fontSize: '20px' }} className='text-secondary'>Session expired. Please sign in again</h5>
                    </div>
                    <div className="modal-footer justify-content-center">
                        <button type="button" onClick={logoutAction} className="btn btn-warning">Ok</button>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default SessionOutModal