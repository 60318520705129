
// CSV File Export Column Options for Permission to User or Allow

export const columnOptions = [
    { value: 0, label: "Order ID" },
    { value: 1, label: "Order Number" },
    { value: 2, label: "Integrator Number"},
    { value: 3, label: "Shop ID" },
    { value: 4, label: "Shop Order Number" },
    { value: 5, label: "Shop Name" },
    { value: 6, label: "Driver Name" },
    { value: 7, label: "Driver Phone" },
    { value: 8, label: "Buyer Name" },
    { value: 9, label: "Buyer Phone" },
    { value: 10, label: "Destination" },
    { value: 11, label: "KM" },
    { value: 12, label: "Delivery Fee" },
    { value: 13, label: "Price" },
    { value: 14, label: "Utrac Fee" },
    { value: 15, label: "Tax Fee" },
    { value: 16, label: "Reason" },
    { value: 17, label: "Payment Method" },
    { value: 18, label: "Created At" },
    { value: 19, label: "Accepted At" },
    { value: 20, label: "Arrived to shop" },
    { value: 21, label: "Picked At" },
    { value: 22, label: "Delivered At" },
    { value: 23, label: "Status" },
    { value: 24, label: "Creation To Accept" },
    { value: 25, label: "Accept To Arrive" },
    { value: 26, label: "Arrive To Pickup" },
    { value: 27, label: "Creation To Deliver" },
];

// Open Order Actions for Permission to User or Allow
export const orderActionOptions = [
    { value: 0, label: 'Assign-Order' },
    { value: 1, label: 'Arrived-To-Shop' },
    { value: 2, label: 'Pick-Order' },
    { value: 3, label: 'Complete-Order' },
    { value: 4, label: 'Cancel-Order' },
    { value: 5, label: 'Re-Order' },
    { value: 6, label: 'Re-Notify' },
    { value: 7, label: 'Re-Order-Utrac' },
    { value: 8, label: 'Push-Messages' },
    { value: 9, label: 'Edit-Order' },
    { value: 10, label: 'Track-Order' },
    { value: 11, label: 'Track-Buyer-Location' },
    { value: 12, label: 'Send-Message' },
    { value: 13, label: 'Re-Schedule' },
    { value: 14, label: 'Hold-Order' },
    { value: 15, label: 'Resume-Order' },
    { value: 16, label: 'Add-Comment' },
    { value: 17, label: 'WhatsApp-Message'},
    { value: 18, label: 'Wallet-Recharge'},
    { value: 19, label: 'Text-Message'},
    { value: 20, label: 'Schedule-Order'},

];

export const adminActionOptions = [
    { value: 0, label: 'Wallet-Recharge'},
];

// Other permissiopn for DSP user 
export const userPermission = [
    { value: 0, label: 'View-Created-Orders' },
    { value: 1, label: 'View-Shift-Drivers' },
];

export const directionOptions = [
    { value: 'center_1', label: 'Center 1' },
    { value: 'center_2', label: 'Center 2' },
    { value: 'center_3', label: 'Center 3' },
    { value: 'east_1', label: 'East 1' },
    { value: 'east_2', label: 'East 2' },
    { value: 'north_1', label: 'North 1' },
    { value: 'north_2', label: 'North 2' },
    { value: 'south_1', label: 'South 1' },
    { value: 'south_2', label: 'South 2' },
    { value: 'west_1', label: 'West 1' },
    { value: 'west_2', label: 'West 2' },
];

export const shopDirections = [
    { value: 'Center', label: 'Center' },
    { value: 'Center 1', label: 'Center 1' },
    { value: 'Center 2', label: 'Center 2' },
    { value: 'center 3', label: 'Center 3' },
    { value: 'East', label: 'East' },
    { value: 'East 1', label: 'East 1' },
    { value: 'East 2', label: 'East 2' },
    { value: 'North', label: 'North' },
    { value: 'North 1', label: 'North 1' },
    { value: 'North 2', label: 'North 2' },
    { value: 'South', label: 'South' },
    { value: 'South 1', label: 'South 1' },
    { value: 'South 2', label: 'South 2' },
    { value: 'West', label: 'West' },
    { value: 'West 1', label: 'West 1' },
    { value: 'West 2', label: 'West 2' },
];

export const weekDays = [
    { value: 'monday', label: 'Monday' },
    { value: 'tuesday', label: 'Tuesday' },
    { value: 'wednesday', label: 'Wednesday' },
    { value: 'thursday', label: 'Thursday' },
    { value: 'friday', label: 'Friday' },
    { value: 'saturday', label: 'Saturday' },
    { value: 'sunday', label: 'Sunday' },
]

export const timeOptions = [
    { value: '00:00', label: '00:00' },
    { value: '00:30', label: '00:30' },
    { value: '01:00', label: '01:00' },
    { value: '01:30', label: '01:30' },
    { value: '02:00', label: '02:00' },
    { value: '02:30', label: '02:30' },
    { value: '03:00', label: '03:00' },
    { value: '03:30', label: '03:30' },
    { value: '04:00', label: '04:00' },
    { value: '04:30', label: '04:30' },
    { value: '05:00', label: '05:00' },
    { value: '05:30', label: '05:30' },
    { value: '06:00', label: '06:00' },
    { value: '06:30', label: '06:30' },
    { value: '07:00', label: '07:00' },
    { value: '07:30', label: '07:30' },
    { value: '08:00', label: '08:00' },
    { value: '08:30', label: '08:30' },
    { value: '09:00', label: '09:00' },
    { value: '09:30', label: '09:30' },
    { value: '10:00', label: '10:00' },
    { value: '10:30', label: '10:30' },
    { value: '11:00', label: '11:00' },
    { value: '11:30', label: '11:30' },
    { value: '12:00', label: '12:00' },
    { value: '12:30', label: '12:30' },
    { value: '13:00', label: '13:00' },
    { value: '13:30', label: '13:30' },
    { value: '14:00', label: '14:00' },
    { value: '14:30', label: '14:30' },
    { value: '15:00', label: '15:00' },
    { value: '15:30', label: '15:30' },
    { value: '16:00', label: '16:00' },
    { value: '16:30', label: '16:30' },
    { value: '17:00', label: '17:00' },
    { value: '17:30', label: '17:30' },
    { value: '18:00', label: '18:00' },
    { value: '18:30', label: '18:30' },
    { value: '19:00', label: '19:00' },
    { value: '19:30', label: '19:30' },
    { value: '20:00', label: '20:00' },
    { value: '20:30', label: '20:30' },
    { value: '21:00', label: '21:00' },
    { value: '21:30', label: '21:30' },
    { value: '22:00', label: '22:00' },
    { value: '22:30', label: '22:30' },
    { value: '23:00', label: '23:00' },
    { value: '23:30', label: '23:30' }
]