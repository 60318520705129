import React, { useState, useEffect, useContext } from 'react';
import OrderDetailsModel from '../../Components/DetailsModel/OrderDetails';
import OrderNotesModel from '../../Components/DetailsModel/OrderNotes';
import ExportModel from '../../Components/ConfirmModel/ExportData';
import EditBuyerModal from './EditBuyerModal';
import { useSelector } from 'react-redux';
import IsLoadingHOC from '../../Components/IsLoadingHOC';
import IsLoggedinHOC from '../../Components/IsLoggedinHOC';
import DriversRosterList from '../../Pages/DriversRoster/DriversRosterList';
import Pagination from '../../Components/Pagination';
import SearchForm from '../../Components/SearchOrder';
import { openHeader } from '../../utils/OrdersCSV';
import { Link, useHistory, useLocation } from 'react-router-dom';
import AssignOrderModal from './AssignOrderModal';
import ReasonModal from './SelectReasonModal';
import ReorderModal from './ReorderModal';
import PushMessageModal from './PushMessageModal';
import TableFooter from '../../Components/TableFooter';
import { exportToExcel } from '../../Helper';
import {
   checkWhatsAppMsg,
   dateFormat,
   getBuyerLoaction,
   handleCopyClipboard,
   isOrderNotes,
   setStatusColor,
   trimObjValues
} from '../../Helper';
import {
   isAllItemSelected,
   handleSelectListItem,
   isIntegratorNumber,
   isShopOrderNumber,
   showShopDirection,
   setDeliveryETA
} from '../../Helper';
import {
   arrivedToShopApi,
   cancelBulkOrdersApi,
   getOrdersList,
   keepOrderOnHoldApi,
   resumeOrderApi,
   updateBuyerOrder
} from '../../Redux/action/Orders';
import {
   assignOrderToDriver,
   pickOrderApi,
   cancelOrderApi,
   completeOrderApi,
   reorderCreated,
   reorderPendingPick,
   reNotifyOrder,
   createToScheduleOrderApi
} from '../../Redux/action/Orders';
import AssignWithMapModal from './AssignWithMapModal';
import { confirmAlert } from 'react-confirm-alert';
import AddCommentModal from './AddCommentModal';
import ViewComments from "../../Components/DetailsModel/ViewComments";
import { removeOpenOrder, setNotifyOrder } from '../../Redux/action/App';
import SendWhatsAppMessageModal from './SendWhatsAppMessageModal';
import SendTextMessageModal from './SendTextMessageModal';
import WhatsAppMsges from '../../Components/DetailsModel/WhatsAppMsges';
import { SidebarContext } from '../../Context/SidebarContext';
import { OrderContext } from '../../Context/OrderContext';
import NotFoundData from '../../Components/NotFoundData';
import { CSVLink } from 'react-csv';
import moment from 'moment';


function OpenOrders(props) {
   const { isLoading, setLoading } = props
   const location = useLocation();
   const history = useHistory();
   const pathName = location.pathname
   const { user } = useSelector(state => state.auth)
   const { _id, type, allowed_action_permission, allowed_permission, allowed_order_export_column, drivers, own_drivers } = user ? user : {}
   const logisticsDrivers = drivers ? drivers : []
   const [assignModal, setAssignModal] = useState(false)
   const [rightSlider, setRightSlider] = useState(false)
   const [assignWithMap, setAssignWithMap] = useState(false)
   const [cancelModel, setCancelModel] = useState(false)
   const [pushMsgModel, setPushMsgModel] = useState(false)
   const [reorderModel, setReorderModel] = useState(false)
   const [editBuyerModel, setEditBuyerModel] = useState(false)
   const [addCommentModal, setAddCommentModal] = useState(false)
   const [sendWhatsAppMsgModal, setSendWhatsAppMsgModal] = useState(false)
   const [sendTextMsgModal, setSendTextMsgModal] = useState(false)
   const [onHoldModal, setOnHoldModal] = useState(false)
   const [loader, setLoader] = useState(false)
   const [openOrders, setOpenOrders] = useState([])
   const [actionType, setActionType] = useState("");
   const [selectedValue, setSelectedValue] = useState({ shopid: null, driverid: null });
   const { branches_id } = useSelector(state => state.shop)
   const [selectedBranches, setSelectedBranches] = useState([])
   const { notifyOrder, removeOrder } = useSelector(state => state.app)
   const { orderETA } = useSelector(state => state.app)

   let exportsColumns = []
   if (type === "Admin") {
      exportsColumns = openHeader
   }
   else {
      const filteredArray = openHeader.filter(item => allowed_order_export_column?.includes(item.label))
      exportsColumns = filteredArray || []
   }

   const {
      orderDetailModal,
      setOrderDetailModal,
      orderNotesModal,
      setOrderNotesModal,
      viewCommentModal,
      setViewCommentModal,
      whatsAppMsgesModal,
      setWhatsAppMsgesModal
   } = useContext(OrderContext)

   const {
      csvLink,
      dispatch,
      totalCount,
      toast,
      sorting,
      handleSubmit,
      handleSorting,
      setTotalCount,
      currentPage,
      resetForm,
      setcurrentPage,
      itemsPerPage,
      exportsData,
      setExportsData,
      selectedDate,
      setSelectedDate,
      selectedItem,
      setSelectedItem,
      handleSelectItem,
      fileLoading,
      setFileLoading,
      orderDetails,
      setOrderDetail,
      orderId,
      setOrderId,
      exportModal,
      toggleExportModal,
      maxPageNumberLimit,
      setmaxPageNumberLimit,
      minPageNumberLimit,
      setminPageNumberLimit,
   } = useContext(SidebarContext)


   let ordersType = 'Open Orders';
   let orderStatus = "created,pending pickup,arrived,picked";
   switch (pathName) {
      case '/all-orders':
         orderStatus = "created,pending pickup,arrived,picked,hold,completed,canceled";
         ordersType = 'All Orders';
         break;
      case '/arrived-orders':
         orderStatus = 'arrived';
         ordersType = 'Arrived Orders';
         break;
      case '/pending-pickup':
         orderStatus = 'created,pending pickup';
         ordersType = 'Created | Pending Pickup Orders';
         break;
      case '/picked-orders':
         orderStatus = 'picked';
         ordersType = 'Picked Orders';
         break;
      case '/on-hold-orders':
         orderStatus = 'hold';
         ordersType = 'On Hold Orders';
         break;
      default:
         break;
   }

   const [searchParams, setSearchParams] = useState({
      status: orderStatus,
      shopname: "",
      shopid: ["Admin", "Logistics"].includes(user.type) ? "" : branches_id,
      driverid: ["Logistics"].includes(user.type) ? drivers.join(',') : "",
      branch_ids: "",
      ordernumber: "",
      drivername: "",
      receiver_name: "",
      buyerphone: "",
      receiver_address: "",
      from_date: "",
      to_date: "",
   })

   useEffect(() => {
      if (orderETA) {
         setOpenOrders(openOrders => {
            return openOrders.map(order => {
               if (order._id === orderETA.order_id) {
                  const updatedOrder = { ...order };
                  if (orderETA.order_status === "pending pickup") {
                     updatedOrder.pick_eta = orderETA.eta_value;
                  } else {
                     updatedOrder.delivery_eta = orderETA.eta_value;
                  }
                  updatedOrder.status = orderETA.order_status;
                  return updatedOrder;
               }
               return order;
            });
         });
      }
   }, [orderETA]);


   useEffect(() => {
      if (notifyOrder?._id) {
         if (notifyOrder.status === 'created') {
            if (openOrders.find(item => item._id === notifyOrder._id)) {
               setOpenOrders((prevOrders) =>
                  prevOrders.map((item) => (item._id === notifyOrder._id ? (Object.assign(item, notifyOrder)) : item))
               );
            }
            else {
               setOpenOrders((prevOrders) => [notifyOrder, ...prevOrders]);
            }
         }
         else if (notifyOrder.status === 'completed' && pathName !== '/all-orders') {
            const result = openOrders.filter(item => item._id !== notifyOrder._id)
            setOpenOrders(result)
         }

         else if (notifyOrder.status === 'hold' && pathName !== '/all-orders') {
            const result = openOrders.filter(item => item._id !== notifyOrder._id)
            setOpenOrders(result)
         }

         else {
            setOpenOrders((prevOrders) =>
               prevOrders.map((item) => (item._id === notifyOrder._id ? (Object.assign(item, notifyOrder)) : item))
            );
         }
         if (user.type === 'Logistics' && removeOrder._id) {
            const orderExistsInOpenOrders = openOrders.some(item => item._id === removeOrder._id);
            if (orderExistsInOpenOrders && removeOrder.status !== 'created' && !logisticsDrivers.includes(removeOrder?.driverid)) {
               const result = openOrders.filter(item => item._id !== removeOrder._id);
               setOpenOrders(result);
            }
         }
         dispatch(setNotifyOrder(""))
         dispatch(removeOpenOrder(""))
      }
   }, [notifyOrder, removeOrder]);


   useEffect(() => {
      getOpenOrdersData();
   }, [currentPage, sorting])

   const handleSelectShop = (option, name) => {
      let { value } = option ? option : {}
      if (value) {
         setSearchParams((prevState) => ({
            ...prevState,
            [name]: value,
         }));
      }
      else {
         if (user.type === "Admin") {
            setSearchParams((prevState) => ({
               ...prevState,
               [name]: "",
            }));
         }
         else {
            setSearchParams((prevState) => ({
               ...prevState,
               [name]: _id,
            }));
         }
      }
      setSelectedValue((prevState) => ({
         ...prevState,
         [name]: option,
      }));
   }

   const handleSelectDriver = (option, name) => {
      let { value } = option ? option : {}
      setSearchParams((prevState) => ({
         ...prevState,
         [name]: value || '',
      }));
      setSelectedValue((prevState) => ({
         ...prevState,
         [name]: option,
      }));
   }

   const handleSelectBranch = (options) => {
      const selectedValues = []
      options.map(item => selectedValues.push(item.value))
      setSearchParams((prevState) => ({
         ...prevState,
         branch_ids: selectedValues
      }));
      setSelectedBranches(options)
   }

   const handleSearch = (e) => {
      const { name, value } = e.target
      setSearchParams((prevState) => ({
         ...prevState,
         [name]: value,
      }));
   }

   const handleChangeDate = (date, name) => {
      setSearchParams((prevState) => ({
         ...prevState,
         [name]: date ? moment(date).format("YYYY-MM-DD") : '',
      }));
      setSelectedDate((prevState) => ({
         ...prevState,
         [name]: date
      }));
   }

   const getOpenOrdersData = async () => {
      if (user.type === "Logistics" && (drivers?.length === 0)) {
         return;
      }
      const trimmedSearchParams = trimObjValues(searchParams)
      const payload = {
         search: { ...trimmedSearchParams },
         shorting: sorting,
         pagination: { start: currentPage, length: itemsPerPage }
      }
      setLoading(true)
      await dispatch(getOrdersList(payload))
         .then(
            response => {
               setLoading(false);
               if (response.status === "1") {
                  setOpenOrders(response.data.orders)
                  setTotalCount(response.data.total_count)
               } else {
                  setOpenOrders([])
                  setTotalCount(null)
               }
            },
            error => {
               setLoading(false)
               toast.error(error.response?.data.message)
            }
         )
   }

   const getExportingData = async () => {
      if (user.type === "Logistics" && (!drivers || drivers.length === 0)) {
         return;
      }
      const trimmedSearchParams = trimObjValues(searchParams);
      const totalItem = [];
      const fetchOrders = async (pagination) => {
         const payload = {
            search: { ...trimmedSearchParams },
            selected_items: pagination.start === 1 ? [...selectedItem] : [],
            shorting: sorting,
            pagination: pagination
         };
         try {
            setFileLoading(true);
            const response = await dispatch(getOrdersList(payload));
            setFileLoading(false);

            if (response.status === "1") {
               totalItem.push(...response.data.orders);
            }
         } catch (error) {
            setFileLoading(false);
         }
      };

      if (selectedItem && selectedItem.length > 0) {
         await fetchOrders({ start: 1, length: 1000 });
      } else {
         const pages = Math.ceil(totalCount / 50000);
         for (let i = 1; i <= pages; i++) {
            await fetchOrders({ start: i, length: 50000 });
         }
      }
      //setExportsData(totalItem);

      const exportData = totalItem?.map((item) => {
         const exportItem = {};
         exportsColumns.forEach(header => {
            exportItem[header.label] = item[header.key];
         });
         return exportItem;
      });

      const fileName = `Open-Orders-${moment(new Date()).format("hh-mm-ss")}.xlsx`
      exportToExcel(exportData, fileName)

      // csvLink.current.link.click();
      toast.success("Data exported successfully");
      setSelectedItem([])
      toggleExportModal()
   };

   const handleReset = async () => {
      const resetFormData = {
         status: orderStatus,
         shopname: "",
         shopid: ["Admin", "Logistics"].includes(user.type) ? "" : branches_id,
         driverid: ["Logistics"].includes(user.type) ? drivers.join(',') : "",
         branch_ids: "",
         ordernumber: "",
         drivername: "",
         receiver_name: "",
         buyerphone: "",
         receiver_address: "",
         from_date: "",
         to_date: "",
      }
      setSearchParams(resetFormData)
      resetForm();
      setSelectedValue({ shopid: null, driverid: null })
      setSelectedBranches([])
   }

   const handleCommonLogic = (item, extraLogic = {}) => {
      if (item && item._id) {
         setOrderDetail(item);
         setOrderId(item._id);
      }
      if (extraLogic.actionType) {
         setActionType(extraLogic.actionType)
      }
   };

   const createHandler = (modalSetter, actionSetter) => (item) => {
      modalSetter(true);
      if (actionSetter) {
         actionSetter(true);
      }
      handleCommonLogic(item);
   };

   const handleAssignModel = createHandler(setAssignModal);
   const handleAssignModelWithMap = createHandler(setAssignWithMap);
   const handelCancelModel = createHandler(setCancelModel);
   const showOnHoldModal = createHandler(setOnHoldModal);
   const handleOrderDetailModel = (order_id) => {
      setOrderDetailModal(!orderDetailModal);
      handleCommonLogic({ _id: order_id });
   };
   const handleViewCommentsModal = createHandler(setViewCommentModal);
   const handleOrderNotesModel = createHandler(setOrderNotesModal);
   const handlePushmsgModel = createHandler(setPushMsgModel);
   const handleEditBuyerModel = createHandler(setEditBuyerModel);

   const handleReorderModel = (orderId, status) => {
      setReorderModel(!reorderModel);
      handleCommonLogic({ _id: orderId }, { actionType: status });
   };

   const handleCommentModel = createHandler(setAddCommentModal);
   const handleSendWhatsappMsgModal = createHandler(setSendWhatsAppMsgModal);
   const handleSendTextMsgModal = createHandler(setSendTextMsgModal);

   const showWhatsAppMessagesModal = createHandler(setWhatsAppMsgesModal);



   const handleAssignOrder = async (driverId) => {
      setLoader(true);
      const assignOrder = async (orderId) => {
         const payload = {
            order_id: orderId,
            driver_id: driverId,
         };

         try {
            const response = await dispatch(assignOrderToDriver(payload));
            if (response.status === '1') {
               //  toast.success(response.data.message);
               const order = (response.data && response.data.order[0]) || {};
               const result = openOrders.map(item => {
                  if (item._id === orderId && order?._id) {
                     return { ...order };
                  }
                  return item;
               });
               setOpenOrders(result);
            } else {
               toast.error(response.data?.error[0]);
            }
         } catch (error) {
            console.log("errorResponse", error.response);
            const errorMsg = error.response.data.data.error[0] || error.response.data.message;
            toast.error(errorMsg);
         }
      };

      if (selectedItem && selectedItem.length > 0) {
         const selectedOrders_Ids = [...selectedItem];
         for (const order_Id of selectedOrders_Ids) {
            await assignOrder(order_Id);
         }
      } else {
         await assignOrder(orderId);
      }
      setLoader(false);
      setAssignModal(false);
      setAssignWithMap(false);
      setSelectedItem([])
   };


   const arrivedToShop = async (orderid) => {
      const payload = { order_id: orderid }
      setLoading(true)
      await dispatch(arrivedToShopApi(payload))
         .then(
            response => {
               setLoading(false)
               if (response.status === '1') {
                  toast.success(response.data.message)
                  const order = (response.data && response.data.order[0]) || {}
                  const result = openOrders.map(item => {
                     if (item._id === orderid && order?._id) {
                        return { ...order };
                     }
                     return item
                  })
                  setOpenOrders(result)
               } else {
                  toast.error(response.data?.error[0])
               }
            },
            error => {
               setLoading(false)
               toast.error(error.response?.data.message)
            }
         )
   }

   const handlePickupOrder = async (orderid) => {
      const payload = { order_id: orderid }
      setLoading(true)
      await dispatch(pickOrderApi(payload))
         .then(
            response => {
               setLoading(false)
               if (response.status === '1') {
                  toast.success(response.data.message)
                  const order = (response.data && response.data.order[0]) || {}
                  const result = openOrders.map(item => {
                     if (item._id === orderid && order?._id) {
                        return { ...order };
                     }
                     return item
                  })
                  setOpenOrders(result)
               } else {
                  toast.error(response.data?.error[0])
               }
            },
            error => {
               setLoading(false)
               toast.error(error.response?.data.message)
            }
         )
   }

   const cancelBulkOrders = async (cancelationReason, otherReason) => {
      let payload = { note: cancelationReason, orders: selectedItem }
      if (otherReason) {
         payload.description = otherReason
      }
      setLoader(true)
      await dispatch(cancelBulkOrdersApi(payload))
         .then(
            response => {
               setLoader(false)
               if (response.status === '1') {
                  toast.success("Order has been canceled successfully.")
                  setCancelModel(false)
                  setSelectedItem([])
                  getOpenOrdersData()
               } else {
                  toast.error(response.data?.error[0])
               }
            },
            error => {
               setLoader(false)
               toast.error(error.response?.data.message)
            }
         )
   }

   const handleCancelOrder = async (cancelationReason, otherReason) => {
      if (selectedItem && selectedItem.length > 0) {
         cancelBulkOrders(cancelationReason, otherReason);
      }
      else {
         let payload = { order_id: orderId, note: cancelationReason }
         if (otherReason) {
            payload.note_description = otherReason
         }
         setLoader(true)
         await dispatch(cancelOrderApi(payload))
            .then(
               response => {
                  setLoader(false)
                  if (response.status === '1') {
                     toast.success(response.data.message)
                     setCancelModel(false)
                     const order = (response.data && response.data.order[0]) || {}
                     const result = openOrders.map(item => {
                        if (item._id === orderId && order?._id) {
                           return { ...order };
                        }
                        return item
                     })
                     setOpenOrders(result)
                     setSelectedItem([])
                  } else {
                     toast.error(response.data?.error[0])
                  }
               },
               error => {
                  setLoader(false)
                  toast.error(error.response?.data.message)
               }
            )
      }
   }

   const placeOnholdOrder = async (reason, otherReason) => {
      let payload = { order_id: orderId, note: reason }
      if (otherReason) {
         payload.note_description = otherReason
      }
      setLoader(true)
      await dispatch(keepOrderOnHoldApi(payload))
         .then(
            response => {
               setLoader(false)
               if (response.status === '1') {
                  toast.success(response.data.message)
                  setOnHoldModal(false)
                  if (pathName !== '/all-orders') {
                     const order = (response.data && response.data?.order[0]) || {}
                     const result = openOrders.map(item => {
                        if (item._id === orderId && order?._id) {
                           return { ...order };
                        }
                        return item
                     })
                     setOpenOrders(result)
                  }
                  else {
                     const result = openOrders.filter(item => item._id !== orderId)
                     setOpenOrders(result)
                  }
               } else {
                  toast.error(response.data?.error[0])
               }
            },
            error => {
               setLoader(false)
               toast.error(error.response?.data.message)
            }
         )
   }

   const handleResumeOrder = async (order_id) => {
      const payload = { order_id }
      setLoading(true)
      await dispatch(resumeOrderApi(payload))
         .then(
            response => {
               setLoading(false)
               if (response.status === '1') {
                  toast.success(response.data.message)
                  const order = (response.data && response.data.order[0]) || {}
                  const result = openOrders.map(item => {
                     if (item._id === order_id && order && order._id) {
                        return { ...order };
                     }
                     return item
                  })
                  setOpenOrders(result)
               } else {
                  toast.error(response.data?.error[0])
               }
            },
            error => {
               setLoading(false)
               toast.error(error.response?.data.message)
            }
         )
   }

   const handleCompleteOrder = async (orderid) => {
      const payload = { order_id: orderid }
      setLoading(true)
      await dispatch(completeOrderApi(payload))
         .then(
            response => {
               setLoading(false)
               if (response.status === '1') {
                  toast.success(response.data.message)
                  if (pathName !== '/all-orders') {
                     const order = (response.data && response.data?.order[0]) || {}
                     const result = openOrders.map(item => {
                        if (item._id === orderid && order?._id) {
                           return { ...order };
                        }
                        return item
                     })
                     setOpenOrders(result)
                  }
                  else {
                     const result = openOrders.filter(item => item._id !== orderid)
                     setOpenOrders(result)
                  }
               } else {
                  toast.error(response.data?.error[0])
               }
            },
            error => {
               setLoading(false)
               toast.error(error.response?.data.message)
            }
         )
   }

   const handleUpdateBuyerOrder = async (_id, receiver_name, buyerphone, orderprice, shop_direction) => {
      let orderid = _id
      const payload = { order_id: _id, receiver_name, buyerphone, orderprice, shop_direction }
      setLoading(true)
      await dispatch(updateBuyerOrder(payload))
         .then(
            response => {
               setLoading(false)
               if (response.status === '1') {
                  toast.success(response.data.message)
                  setEditBuyerModel(false)
                  const order = (response.data && response.data?.order[0]) || {}
                  const result = openOrders.map(item => {
                     if (item._id === orderid && order?._id) {
                        return { ...order };
                     }
                     return item
                  })
                  setOpenOrders(result)

               } else {
                  toast.error(response.data?.error[0])
               }
            },
            error => {
               setLoading(false)
               toast.error(error.response?.data.message)
            }
         )
   }

   const handleReorderPendingPick = async () => {
      const payload = { order_id: orderId }
      setLoader(true)
      await dispatch(reorderPendingPick(payload))
         .then(
            response => {
               setLoader(false)
               if (response.status === '1') {
                  toast.success(response.data.message)
                  setReorderModel(false)
                  const order = (response.data && response.data.order[0]) || {}
                  const result = openOrders.map(item => {
                     if (item._id === orderId) {
                        return { ...order };
                     }
                     return item
                  })
                  setOpenOrders(result)
               } else {
                  toast.error(response.data?.error[0])
               }
            },
            error => {
               setLoader(false)
               toast.error(error.response?.data.message)
            }
         )
   }

   const handleRenotifyOrder = async () => {
      const payload = { order_id: orderId }
      setLoader(true)
      await dispatch(reNotifyOrder(payload))
         .then(
            response => {
               setLoader(false)
               if (response.status === '1') {
                  toast.success(response.data.message)
                  setReorderModel(false)
               } else {
                  toast.error(response.data?.error[0])
               }
            },
            error => {
               setLoader(false)
               toast.error(error.response?.data.message)
            }
         )
   }

   const handleReorderCreated = async () => {
      const payload = { order_id: orderId }
      setLoader(true)
      await dispatch(reorderCreated(payload))
         .then(
            response => {
               setLoader(false)
               if (response.status === '1') {
                  toast.success(response.data.message)
                  setReorderModel(false)
               } else {
                  toast.error(response.data?.error[0])
               }
            },
            error => {
               setLoader(false)
               toast.error(error.response?.data.message)
            }
         )
   }

   const createToScheduleOrder = async () => {
      const payload = { order_id: orderId }
      setLoading(true)
      await dispatch(createToScheduleOrderApi(payload))
         .then(
            response => {
               setLoading(false)
               if (response.status === '1') {
                  toast.success(response.data.message)
                  const order_id = response.data.order_id
                  setReorderModel(false)
                  if (order_id) {
                     history.push(`/edit-schedule-order/${order_id}`)
                  }
               } else {
                  toast.error(response.data?.error[0])
               }
            },
            error => {
               setLoading(false)
               toast.error(error.response?.data.message)
            }
         )
   }

   const checkAllowedAction = (ActionType) => {
      if (allowed_action_permission?.length > 0) {
         const isAllowed = allowed_action_permission?.includes(ActionType)
         return isAllowed
      }
      else {
         return false
      }
   }

   const confirmAssignOrder = async (orderId, driverId, onClose) => {
      const payload = {
         order_id: orderId,
         driver_id: driverId
      }
      setLoading(true)
      await dispatch(assignOrderToDriver(payload))
         .then(
            response => {
               setLoading(false)
               if (response.status === '1') {
                  toast.success(response.data.message)
                  localStorage.removeItem("driver_id")
                  onClose();
                  const resOrder = (response.data && response.data.order[0]) || {}
                  const result = openOrders.map(item => {
                     if (item._id === orderId && resOrder?._id) {
                        return { ...resOrder };
                     }
                     return item
                  })
                  setOpenOrders(result)
               } else {
                  toast.error(response.data?.error[0])
               }
            },
            error => {
               setLoading(false)
               toast.error(error.response?.data.message)
            }
         )
   }

   const onDropItem = async (event, order) => {
      event.preventDefault();
      if (order.status === 'created') {
         let orderId = order._id
         let driverId = localStorage.getItem("driver_id")
         confirmAlert({
            customUI: ({ onClose }) => {
               return (
                  <div className='custom-ui'>
                     <h1>Are you sure?</h1>
                     <p>You want to assign this order #{order?.ordernumber}</p>
                     <button onClick={onClose}>No</button>
                     <button
                        onClick={() => {
                           confirmAssignOrder(orderId, driverId, onClose)
                        }}
                     >
                        Yes
                     </button>
                  </div>
               );
            }
         });
      }
      else {
         toast.error("Invalid Action!")
      }
   }

   const onDragOverDriver = (event, order) => {
      if (order.status === "created") {
         event.preventDefault();
      }
   };

   return (
      <>
         <div className="content-wrapper">
            <div className="content-header main_breadcrumb_top">
               <div className="container-fluid">
                  <div className="row mb-2">
                     <div className="col-sm-6">
                        <h1 className="m-0">{ordersType}</h1>
                     </div>
                     <div className="col-sm-6">
                        <ol className="breadcrumb float-sm-right">
                           <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                           <li className="breadcrumb-item active">Orders</li>
                        </ol>
                     </div>
                  </div>
               </div>
            </div>
            <section className="content">
               <div className="container-fluid">
                  <div className="row">
                     <div className="col-lg-12">
                        <div className="all_drivers">
                           <div className="card card-dark">
                              <div className="card-header">
                                 <div className="container-fluid">
                                    <div className="row">
                                       <div className="col-6 col-lg-11">
                                          <h3 className="pl-5">Search</h3>
                                       </div>
                                       <div className="col-6 col-lg-1 d-flex justify-content-center align-items-center">
                                          <i className="fas fa-angle-up click_search"></i>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                              <form className="form_search">
                                 <SearchForm
                                    searchParams={searchParams}
                                    selectedValue={selectedValue}
                                    selectedDate={selectedDate}
                                    handleSearch={handleSearch}
                                    handleSelectShop={handleSelectShop}
                                    handleSelectDriver={handleSelectDriver}
                                    handleChangeDate={handleChangeDate}
                                    handleSelectBranch={handleSelectBranch}
                                    selectedBranches={selectedBranches}
                                 />
                                 <div className="card-footer text-center">
                                    <button type="reset" className="btn btn-primary mr-2" onClick={handleReset} >Reset</button>
                                    <button type="button" onClick={() => handleSubmit(getOpenOrdersData)} className="btn btn-success">Submit</button>
                                 </div>
                              </form>
                           </div>
                           <div className="row pagination_new flex-wrap">
                              <div className="col-md-8 d-flex">
                                 {(user && (user.type === "Admin" || user.type === "Shop")) &&
                                    <div>
                                       <Link to="/create-order">
                                          <button disabled={isLoading} type="add" className="btn btn-primary mr-2"><i className="fa-regular fa-circle-plus"></i>Create</button>
                                       </Link>
                                    </div>
                                 }
                                 <div>
                                    <button disabled={isLoading} type="button" onClick={() => handleSubmit(getOpenOrdersData)} className="btn btn-secondary"><i className={`fa-regular fa-rotate-right ${isLoading && 'fa-spin'}`}></i>Refresh</button>
                                 </div>
                                 {openOrders?.length >= 1 &&
                                    <div className="dropdown ml-2">
                                       <button type="button" className="btn btn-primary dropdown-toggle" data-toggle="dropdown" aria-expanded="false">
                                          Action..
                                       </button>
                                       <div className="dropdown-menu">
                                          <span>
                                             <span style={{ cursor: 'pointer' }} className="dropdown-item" type='button' disabled={fileLoading || !totalCount || isLoading} onClick={toggleExportModal} ><i className="fa-regular fa-circle-up mr-2"></i>{fileLoading ? "Loading.." : "Export to Excel"}</span>
                                             {/* {type === 'Admin' ?
                                                <CSVLink className='csv-link' target='_blank' ref={csvLink}
                                                   headers={openHeader}
                                                   data={exportsData}
                                                   filename={`Open_Orders_${moment(new Date()).format("YYYY-MMM-DD_hh-mm-ss")}`}
                                                /> :
                                                <CSVLink className='csv-link' target='_blank' ref={csvLink}
                                                   headers={allowedExportColumn && allowedExportColumn.length > 0 ? allowedExportColumn : openHeader}
                                                   data={exportsData}
                                                   filename={`Open_Orders_${moment(new Date()).format("YYYY-MMM-DD_hh-mm-ss")}`}
                                                />
                                             } */}
                                          </span>
                                          {pathName !== '/all-orders' && selectedItem?.length > 0 &&
                                             <React.Fragment>
                                                <div className="dropdown-divider"></div>
                                                <span style={{ cursor: 'pointer' }} className="dropdown-item" onClick={() => setAssignModal(true)}>
                                                   <i className="fa-solid fa-truck mr-2" aria-hidden="true"></i>Assign Order
                                                </span>
                                             </React.Fragment>
                                          }
                                          {pathName !== '/all-orders' && selectedItem?.length > 0 &&
                                             <React.Fragment>
                                                <div className="dropdown-divider"></div>
                                                <span style={{ cursor: 'pointer' }} className="dropdown-item text-danger" onClick={() => setCancelModel(true)}><span className="red_1">
                                                   <i className="fa-regular fa-circle-xmark mr-2" aria-hidden="true"></i>Cancel order</span></span>
                                             </React.Fragment>
                                          }
                                       </div>
                                    </div>
                                 }
                              </div>
                              {((user.type === "Admin") || (own_drivers?.length > 0) || allowed_permission.includes("View-Shift-Drivers")) && (["/all-orders", "/open-orders", "/pending-pickup"].includes(pathName)) &&
                                 <div className="col-md-4 text-lg-right text-center">
                                    <button onClick={() => setRightSlider(!rightSlider)} disabled={isLoading} type="button" className="btn btn-primary">View Drivers</button>
                                 </div>
                              }
                           </div>
                           {openOrders?.length > 0 &&
                              <React.Fragment>
                                 <div className="card table_card completed_1">
                                    <div className="card-body all_drive_table">
                                       <table id="example2" className="table table-hover">
                                          <thead>
                                             <tr>
                                                <th><input type="checkbox" id="check" checked={isAllItemSelected(openOrders, selectedItem)} onChange={(event) => handleSelectListItem(event, openOrders, selectedItem, setSelectedItem)} className="user_table_checkbox" /></th>
                                                <th>More info</th>
                                                <th>Order</th>
                                                <th>Shop</th>
                                                <th>Buyer</th>
                                                <th>Driver
                                                   {sorting.order_by === "drivername" ?
                                                      <i onClick={() => handleSorting("drivername")}
                                                         className={`fas ${sorting.order_in === 1 ? "fa-sort-amount-down-alt" : "fa-sort-amount-down"}`}>
                                                      </i>
                                                      : <i onClick={() => handleSorting("drivername")} className="fa-solid fa-arrow-down-arrow-up"></i>
                                                   }
                                                </th>
                                                <th>Status</th>
                                                <th>Created At
                                                   {sorting.order_by === "_created_at" ?
                                                      <i onClick={() => handleSorting("_created_at")}
                                                         className={`fas ${sorting.order_in === 1 ? "fa-sort-amount-down-alt" : "fa-sort-amount-down"}`}>
                                                      </i>
                                                      : <i onClick={() => handleSorting("_created_at")} className="fa-solid fa-arrow-down-arrow-up"></i>
                                                   }
                                                </th>
                                                <th>Action</th>
                                             </tr>
                                          </thead>
                                          <tbody>
                                             {openOrders?.map((item, i) => (
                                                <tr key={item._id}
                                                   onDragOver={(event) => onDragOverDriver(event, item)}
                                                   onDrop={(event) => onDropItem(event, item)}
                                                   className="accordion-toggle" style={{ backgroundColor: item?.location_shared === true && '#e8eddf' }}
                                                >
                                                   <td>
                                                      <input type="checkbox" className="table_checkbox"
                                                         onChange={(event) => handleSelectItem(event, item._id)}
                                                         checked={selectedItem.includes(item._id)} />
                                                   </td>
                                                   <td>
                                                      <div onClick={() => handleOrderDetailModel(item._id)} title='Order-Info'><i className="fa-solid fa-circle-info fa-lg"></i></div>
                                                      {
                                                         item.have_operation_comments &&
                                                         <div onClick={() => handleViewCommentsModal(item)} title='View-comments'><i className="fa-solid fa-comment fa-lg"></i>
                                                         </div>
                                                      }
                                                      {
                                                         isOrderNotes(item.order_notes) &&
                                                         <div onClick={() => handleOrderNotesModel(item)} title='View-notes'><i className="fa-solid fa-note fa-lg"></i></div>
                                                      }
                                                      {
                                                         checkWhatsAppMsg(item.order_history) &&
                                                         <div className='text-success' onClick={() => showWhatsAppMessagesModal(item)} title='View-message'><i className="fa-brands fa-whatsapp  fa-lg"></i></div>
                                                      }
                                                   </td>
                                                   <td>
                                                      <div onClick={() => handleCopyClipboard(item._id)} className='cursor-pointer' title='Order-number'>
                                                         {item.requested_vehicle_type === "bike" && <i className="fa-solid fa-motorcycle"></i>}
                                                         {item.requested_vehicle_type === "car" && <i className="fa-solid fa-car"></i>}
                                                         {item.requested_vehicle_type === "truck" && <i className="fa-sharp fa-solid fa-truck"></i>}
                                                         {item.requested_vehicle_type === "van" && <i className="fa-solid fa-van-shuttle"></i>}
                                                         {item.requested_vehicle_type === "all" && <i className="fa-sharp fa-solid fa-truck"></i>}
                                                         {item.ordernumber}
                                                      </div>
                                                      {isIntegratorNumber(item?.integrator_number)}
                                                      {isShopOrderNumber(item?.shop_ordernumber)}
                                                      {["pending pickup", "picked"].includes(item.status) &&
                                                         <div className='cursor-pointer' title={item.status === 'pending pickup' ? 'Pickup-ETA' : 'Deliver-ETA'}>
                                                            <i className="fa-solid fa-clock"></i>{setDeliveryETA(item.status === "pending pickup" ? item?.pick_eta : item?.delivery_eta)}
                                                         </div>
                                                      }
                                                      <div className='text-success' role="button" title='Order-Price'><i className="fa-solid fa-coins"></i>{item?.orderprice}</div>
                                                      <div className='text-capitalize text-secondary' role="button" title='Payment-Method'><i className="fa-duotone fa-credit-card"></i>{item?.paymentmethod}</div>
                                                   </td>
                                                   <td>
                                                      <div title={item.shopname}><i className="fa-solid fa-store"></i>{item.shopname}</div>
                                                      {item?.senderphone && <div title={item.senderphone}><i className="fa fa-phone" aria-hidden="true"></i>{item?.senderphone}</div>}
                                                      <div className='text-capitalize'><i className="fa-solid fa-diamond-turn-right"></i>{showShopDirection(item)}</div>
                                                   </td>
                                                   <td>
                                                      <div title={item.receiver_name}><i className="fa fa-user" aria-hidden="true"></i>{item.receiver_name}</div>
                                                      <div>
                                                         <i className="fa fa-phone" aria-hidden="true"></i>{item.buyerphone ? item.buyerphone : "Not Available"} </div>
                                                      <div title={getBuyerLoaction(item, 'unlimited')}>
                                                         <i className="fa-solid fa-location-dot"></i>
                                                         {getBuyerLoaction(item, 'limited')}
                                                      </div>
                                                   </td>
                                                   <td>
                                                      <div title={item.drivername}><i className="fa fa-user" aria-hidden="true"></i>{item.drivername}</div>
                                                      <div> <i className="fa fa-phone" aria-hidden="true"></i>{item.driverphone ? item.driverphone : "Not Available"}</div>
                                                      {item.driver?.length > 0 && item.driver[0]?.job_directions && item.driver[0]?.job_directions.length > 0 && <div>
                                                         <span className='text-capitalize'>
                                                            <i className="fa-solid fa-diamond-turn-right"></i>
                                                            {item.driver[0]?.job_directions?.join(' , ')}
                                                         </span>
                                                      </div>
                                                      }
                                                   </td>
                                                   <td>
                                                      <div style={setStatusColor(item.status)} className={`text-capitalize status--bold`}>{item.status ? item.status : "Not Available"}</div>
                                                   </td>
                                                   <td>
                                                      <div>{dateFormat(item._created_at)}</div>
                                                      {item?.delivery_type && item?.delivery_type?.title &&
                                                         <div><span className='text-bold'>{item?.delivery_type?.title}</span>
                                                         </div>
                                                      }
                                                   </td>
                                                   <td>
                                                      <div className="action_button">
                                                         <div className="btn-group">
                                                            <button type="button" className="btn dropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                               <img src={require("../../assets/dist/img/action.png")} alt="action-btn" />
                                                            </button>
                                                            {type === 'Admin' ?
                                                               <div style={{ zIndex: 9999 }} className="dropdown-menu">
                                                                  {item.buyercurrlocation && item.buyercurrlocation.length > 0 &&
                                                                     <>
                                                                        < a href={`https://www.google.com/maps/search/?api=1&query=${item.buyercurrlocation[0]},${item.buyercurrlocation[1]}`} rel="noreferrer" target="_blank" className="dropdown-item">
                                                                           <span>
                                                                              <i className="fa-regular fa-location-dot"></i>Track buyer
                                                                           </span>
                                                                        </a>
                                                                        <div className="dropdown-divider"></div>
                                                                     </>
                                                                  }
                                                                  {item.senderlocation && item.senderlocation.length > 0 &&
                                                                     <>
                                                                        < a href={`https://www.google.com/maps/search/?api=1&query=${item.senderlocation[0]},${item.senderlocation[1]}`} rel="noreferrer" target="_blank" className="dropdown-item">
                                                                           <span>
                                                                              <i className="fa-regular fa-location-dot"></i>Track shop
                                                                           </span>
                                                                        </a>
                                                                        <div className="dropdown-divider"></div>
                                                                     </>
                                                                  }
                                                                  {item.status === "created" && (
                                                                     <>
                                                                        <span className="dropdown-item" onClick={() => handleReorderModel(item._id, 'Created_Renotify')}>
                                                                           <span className="">
                                                                              <i className="fa-regular fa-bell"></i>Re-notify
                                                                           </span>
                                                                        </span>
                                                                        <div className="dropdown-divider"></div>
                                                                     </>
                                                                  )}
                                                                  {["pending pickup", "picked", "arrived"].includes(item.status) && (
                                                                     <>
                                                                        <Link to={`/track-order/${item._id}`} target="_blank" className="dropdown-item">
                                                                           <span className="green_light_1">
                                                                              <i className="fa-regular fa-location-dot"></i>Track order
                                                                           </span>
                                                                        </Link>
                                                                        <div className="dropdown-divider"></div>
                                                                     </>
                                                                  )}
                                                                  {["created", "pending pickup", "picked", "arrived"].includes(item.status) && (
                                                                     <>
                                                                        <span className="dropdown-item" onClick={() => showOnHoldModal(item)} >
                                                                           <span className="text-warning">
                                                                              <i className="fa-sharp fa-regular fa-circle-pause"></i>Place on hold
                                                                           </span>
                                                                        </span>
                                                                        <div className="dropdown-divider"></div>
                                                                     </>
                                                                  )}
                                                                  {item.status === "created" && (
                                                                     <>
                                                                        <span className="dropdown-item" onClick={() => handleReorderModel(item._id, 'Created_Reorder')}>
                                                                           <span className="text-success">
                                                                              <i className="fa-regular fa-bell"></i>Re-order
                                                                           </span>
                                                                        </span>
                                                                        <div className="dropdown-divider"></div>
                                                                     </>
                                                                  )}
                                                                  {["pending pickup", "arrived", "picked"].includes(item.status) && (
                                                                     <>
                                                                        <span className="dropdown-item" onClick={() => handleReorderModel(item._id, 'Pending_pickup_Reorder')}>
                                                                           <span className="text-success">
                                                                              <i className="fa-regular fa-bell"></i>Re-order
                                                                           </span>
                                                                        </span>
                                                                        <div className="dropdown-divider"></div>
                                                                     </>
                                                                  )}
                                                                  {["created", "pending pickup", "picked", "arrived"].includes(item.status) && (
                                                                     <>
                                                                        <Link to={`/push-messages/${item._id}`} className="dropdown-item">
                                                                           <span className='text-info'>
                                                                              <i className="fa-solid fa-messages"></i>Messages
                                                                           </span>
                                                                        </Link>
                                                                        <div className="dropdown-divider"></div>
                                                                     </>
                                                                  )}
                                                                  {(["created"].includes(item.status) || (item.status === "canceled" && pathName === '/open-orders')) &&
                                                                     (
                                                                        <>
                                                                           <span className="dropdown-item" onClick={() => handleAssignModel(item)}>
                                                                              <i className="fa-solid fa-truck"></i>Assign order
                                                                           </span>
                                                                           <div className="dropdown-divider"></div>
                                                                        </>
                                                                     )}

                                                                  {item.status === "created" && !item?.scheduled_order_id &&
                                                                     (
                                                                        <>
                                                                           <span className="dropdown-item" onClick={() => handleReorderModel(item._id, 'Create_To_Schedule')}>
                                                                              <i className="fa-duotone fa-calendar-days"></i>Schedule order
                                                                           </span>
                                                                           <div className="dropdown-divider"></div>
                                                                        </>
                                                                     )}

                                                                  {(item.status === "created" || (item.status === "canceled" && pathName === '/open-orders')) &&
                                                                     (
                                                                        <span className="dropdown-item" onClick={() => handleAssignModelWithMap(item)}>
                                                                           <i className="fa-solid fa-truck"></i>Assign by map
                                                                        </span>
                                                                     )}
                                                                  {["pending pickup"].includes(item.status) && (
                                                                     <span className="dropdown-item" onClick={() => arrivedToShop(item._id)}>
                                                                        <span className='text-warning'>
                                                                           <i className="fa-regular fa-house-person-return"></i>Arrived to shop
                                                                        </span>
                                                                     </span>
                                                                  )}
                                                                  {["arrived"].includes(item.status) && (
                                                                     <span className="dropdown-item" onClick={() => handlePickupOrder(item._id)}>
                                                                        <span className='text-success'>
                                                                           <i className="fa-solid fa-box-circle-check"></i>Pick order
                                                                        </span>
                                                                     </span>
                                                                  )}
                                                                  {item.status === "picked" && (
                                                                     <span className="dropdown-item text-success" onClick={() => handleCompleteOrder(item._id)}>
                                                                        <i className="fa-regular fa-thumbs-up"></i>Complete
                                                                     </span>
                                                                  )}
                                                                  {["pending pickup", "picked", "arrived"].includes(item.status) && (
                                                                     <>
                                                                        <div className="dropdown-divider"></div>
                                                                        <span onClick={() => handlePushmsgModel(item)} className="dropdown-item">
                                                                           <i className="fa-sharp fa-solid fa-paper-plane-top"></i>Send message
                                                                        </span>
                                                                     </>
                                                                  )}
                                                                  {item.hasOwnProperty('scheduled_order_id') && item.scheduled_order_id && item.status === "created" && (
                                                                     <>
                                                                        <div className="dropdown-divider"></div>
                                                                        <Link to={`/re-schedule-order/${item.scheduled_order_id}`}>
                                                                           <span className="dropdown-item">
                                                                              <i className="fa-sharp fa-regular fa-pen-to-square" aria-hidden="true"></i>Re-schedule
                                                                           </span>
                                                                        </Link>
                                                                     </>
                                                                  )}
                                                                  {["created", "pending pickup", "picked", "arrived"].includes(item.status) && (
                                                                     <>
                                                                        <div className="dropdown-divider"></div>
                                                                        <span onClick={() => handleEditBuyerModel(item)} className="dropdown-item">
                                                                           <i className="fa fa-pencil" aria-hidden="true"></i>Edit order
                                                                        </span>
                                                                        <div className="dropdown-divider"></div>
                                                                     </>
                                                                  )}
                                                                  {["created", "pending pickup", "picked", "arrived"].includes(item.status) && (
                                                                     <>
                                                                        <span onClick={() => handelCancelModel(item)} className="dropdown-item">
                                                                           <span className="text-danger"><i className="fa-regular fa-circle-xmark"></i>Cancel</span>
                                                                        </span>
                                                                     </>
                                                                  )}
                                                                  {item.status === "hold" && (
                                                                     <>
                                                                        <span className="dropdown-item" onClick={() => handleResumeOrder(item._id)}>
                                                                           <span className="text-success">
                                                                              <i className="fa-sharp fa-regular fa-circle-play"></i>Resume order
                                                                           </span>
                                                                        </span>
                                                                     </>
                                                                  )}
                                                                  {["created", "pending pickup", "picked", "arrived", "hold"].includes(item.status) && (
                                                                     <>
                                                                        <div className="dropdown-divider"></div>
                                                                        <span onClick={() => handleCommentModel(item)} className="dropdown-item">
                                                                           <i className="fa-solid fa-comment"></i>Add comment
                                                                        </span>
                                                                     </>
                                                                  )}
                                                                  {["created", "pending pickup", "picked", "arrived", "hold"].includes(item.status) && (
                                                                     <>
                                                                        <div className="dropdown-divider"></div>
                                                                        <span onClick={() => handleSendWhatsappMsgModal(item)} className="dropdown-item">
                                                                           <span className='text-success'> <i className="fa-brands fa-whatsapp"></i>Whatsapp Message</span>
                                                                        </span>
                                                                     </>
                                                                  )}
                                                                  {["created", "pending pickup", "picked", "arrived", "hold"].includes(item.status) && (
                                                                     <>
                                                                        <div className="dropdown-divider"></div>
                                                                        <span onClick={() => handleSendTextMsgModal(item)} className="dropdown-item">
                                                                           <i className="fa-regular fa-message-text"></i>Text Message
                                                                        </span>
                                                                     </>
                                                                  )}
                                                               </div> :
                                                               //############------------------  ACTION FOR  SHOP USERS ONLY ---------------------#########################
                                                               <div className="dropdown-menu">
                                                                  {item.buyercurrlocation && item.buyercurrlocation.length > 0 && checkAllowedAction("Track-Buyer-Location") &&
                                                                     <>
                                                                        < a href={`https://www.google.com/maps/search/?api=1&query=${item.buyercurrlocation[0]},${item.buyercurrlocation[1]}`} rel="noreferrer" target="_blank" className="dropdown-item">
                                                                           <span>
                                                                              <i className="fa-regular fa-location-dot"></i>Track buyer
                                                                           </span>
                                                                        </a>
                                                                        <div className="dropdown-divider"></div>
                                                                     </>
                                                                  }
                                                                  {item.senderlocation && item.senderlocation.length > 0 &&
                                                                     <>
                                                                        < a href={`https://www.google.com/maps/search/?api=1&query=${item.senderlocation[0]},${item.senderlocation[1]}`} rel="noreferrer" target="_blank" className="dropdown-item">
                                                                           <span>
                                                                              <i className="fa-regular fa-location-dot"></i>Track shop
                                                                           </span>
                                                                        </a>
                                                                        <div className="dropdown-divider"></div>
                                                                     </>
                                                                  }
                                                                  {item.status === "created" && checkAllowedAction("Re-Notify") && (
                                                                     <>
                                                                        <span className="dropdown-item" onClick={() => handleReorderModel(item._id, 'Created-Renotify')}>
                                                                           <span className="text-success">
                                                                              <i className="fa-regular fa-bell"></i>Re-notify
                                                                           </span>
                                                                        </span>
                                                                        <div className="dropdown-divider"></div>
                                                                     </>
                                                                  )}
                                                                  {["pending pickup", "picked", "arrived"].includes(item.status) && checkAllowedAction("Track-Order") && (
                                                                     <>
                                                                        <Link to={`/track-order/${item._id}`} target="_blank" className="dropdown-item">
                                                                           <span className="text-success">
                                                                              <i className="fa-regular fa-location-dot"></i>Track order
                                                                           </span>
                                                                        </Link>
                                                                        <div className="dropdown-divider"></div>
                                                                     </>
                                                                  )}
                                                                  {["created", "pending pickup", "picked", "arrived"].includes(item.status) && checkAllowedAction("Hold-Order") && (
                                                                     <>
                                                                        <span className="dropdown-item" onClick={() => showOnHoldModal(item)} >
                                                                           <span className="text-warning">
                                                                              <i className="fa-sharp fa-regular fa-circle-pause"></i>Place on hold
                                                                           </span>
                                                                        </span>
                                                                        <div className="dropdown-divider"></div>
                                                                     </>
                                                                  )}
                                                                  {item.status === "created" && checkAllowedAction("Re-Order-Utrac") && (
                                                                     <>
                                                                        <span className="dropdown-item" onClick={() => handleReorderModel(item._id, 'Created-Reorder')}>
                                                                           <span className="text-success">
                                                                              <i className="fa-regular fa-bell"></i>Re-order
                                                                           </span>
                                                                        </span>
                                                                        <div className="dropdown-divider"></div>
                                                                     </>
                                                                  )}
                                                                  {["pending pickup", "arrived"].includes(item.status) && checkAllowedAction("Re-Order") && (
                                                                     <>
                                                                        <span className="dropdown-item" onClick={() => handleReorderModel(item._id, 'Pending_pickup_Reorder')}>
                                                                           <span className="text-success">
                                                                              <i className="fa-regular fa-bell"></i>Re-order
                                                                           </span>
                                                                        </span>
                                                                        <div className="dropdown-divider"></div>
                                                                     </>
                                                                  )}
                                                                  {["created", "pending pickup", "picked", "arrived"].includes(item.status) && checkAllowedAction("Push-Messages") && (
                                                                     <>
                                                                        <Link to={`/push-messages/${item._id}`} className="dropdown-item">
                                                                           <span className='text-info'>
                                                                              <i className="fa-solid fa-messages"></i>Messages
                                                                           </span>
                                                                        </Link>
                                                                        <div className="dropdown-divider"></div>
                                                                     </>
                                                                  )}
                                                                  {(item.status === "created" || (item.status === "canceled" && pathName === '/open-orders')) && checkAllowedAction("Assign-Order") && (
                                                                     <>
                                                                        <span className="dropdown-item" onClick={() => handleAssignModel(item)}>
                                                                           <i className="fa-solid fa-truck"></i>Assign order
                                                                        </span>
                                                                        <div className="dropdown-divider"></div>
                                                                     </>
                                                                  )}
                                                                  {(item.status === "created" || (item.status === "canceled" && pathName === '/open-orders')) && checkAllowedAction("Assign-Order") && (
                                                                     <span className="dropdown-item" onClick={() => handleAssignModelWithMap(item)}>
                                                                        <i className="fa-solid fa-truck"></i>Assign by map
                                                                     </span>
                                                                  )}
                                                                  {["pending pickup"].includes(item.status) && checkAllowedAction("Arrived-To-Shop") && (
                                                                     <span className="dropdown-item" onClick={() => arrivedToShop(item._id)}>
                                                                        <span className='text-warning'>
                                                                           <i className="fa-regular fa-house-person-return"></i>Arrived to Shop
                                                                        </span>
                                                                     </span>
                                                                  )}
                                                                  {["arrived"].includes(item.status) && checkAllowedAction("Pick-Order") && (
                                                                     <span className="dropdown-item" onClick={() => handlePickupOrder(item._id)}>
                                                                        <span className='text-success'>
                                                                           <i className="fa-solid fa-box-circle-check"></i>Pick order
                                                                        </span>
                                                                     </span>
                                                                  )}
                                                                  {item.status === "picked" && checkAllowedAction("Complete-Order") && (
                                                                     <span className="dropdown-item text-success" onClick={() => handleCompleteOrder(item._id)}>
                                                                        <i className="fa-regular fa-thumbs-up"></i>Complete
                                                                     </span>
                                                                  )}
                                                                  {["pending pickup", "picked", "arrived"].includes(item.status) && checkAllowedAction("Send-Message") && (
                                                                     <>
                                                                        <div className="dropdown-divider"></div>
                                                                        <span onClick={() => handlePushmsgModel(item)} className="dropdown-item">
                                                                           <i className="fa-sharp fa-solid fa-paper-plane-top"></i>Send message
                                                                        </span>
                                                                     </>
                                                                  )}


                                                                  {(item.status === "created" && !(item?.scheduled_order_id)) && checkAllowedAction("Schedule-Order") &&
                                                                     (
                                                                        <>
                                                                           <span className="dropdown-item" onClick={() => handleReorderModel(item._id, 'Create_To_Schedule')}>
                                                                              <i className="fa-duotone fa-calendar-days"></i>Schedule order
                                                                           </span>
                                                                           <div className="dropdown-divider"></div>
                                                                        </>
                                                                     )}

                                                                  {item.hasOwnProperty('scheduled_order_id') && item.scheduled_order_id && item.status === "created" && checkAllowedAction("Re-Schedule") && (
                                                                     <>
                                                                        <div className="dropdown-divider"></div>
                                                                        <Link to={`/re-schedule-order/${item.scheduled_order_id}`}>
                                                                           <span className="dropdown-item">
                                                                              <i className="fa-sharp fa-regular fa-pen-to-square" aria-hidden="true"></i>Re-schedule
                                                                           </span>
                                                                        </Link>
                                                                     </>
                                                                  )}
                                                                  {["created", "pending pickup", "picked", "arrived"].includes(item.status) && checkAllowedAction("Edit-Order") && (
                                                                     <>
                                                                        <div className="dropdown-divider"></div>
                                                                        <span onClick={() => handleEditBuyerModel(item)} className="dropdown-item">
                                                                           <i className="fa fa-pencil" aria-hidden="true"></i>Edit order
                                                                        </span>
                                                                        <div className="dropdown-divider"></div>
                                                                     </>
                                                                  )}
                                                                  {["created", "pending pickup", "picked", "arrived"].includes(item.status) && checkAllowedAction("Cancel-Order") && (
                                                                     <>
                                                                        <span onClick={() => handelCancelModel(item)} className="dropdown-item">
                                                                           <span className="text-danger"><i className="fa-regular fa-circle-xmark"></i>Cancel</span>
                                                                        </span>
                                                                     </>
                                                                  )}
                                                                  {item.status === "hold" && checkAllowedAction("Resume-Order") && (
                                                                     <>
                                                                        <span className="dropdown-item" onClick={() => handleResumeOrder(item._id)}>
                                                                           <span className="text-success">
                                                                              <i className="fa-sharp fa-regular fa-circle-play"></i>Resume order
                                                                           </span>
                                                                        </span>
                                                                     </>
                                                                  )}
                                                                  {["created", "pending pickup", "picked", "arrived", "hold"].includes(item.status) && checkAllowedAction("Add-Comment") && (
                                                                     <>
                                                                        <div className="dropdown-divider"></div>
                                                                        <span onClick={() => handleCommentModel(item)} className="dropdown-item">
                                                                           <i className="fa-solid fa-comments"></i>Add comment
                                                                        </span>
                                                                     </>
                                                                  )}
                                                                  {["created", "pending pickup", "picked", "arrived", "hold"].includes(item.status) && checkAllowedAction("WhatsApp-Message") && (
                                                                     <>
                                                                        <div className="dropdown-divider"></div>
                                                                        <span onClick={() => handleSendWhatsappMsgModal(item)} className="dropdown-item">
                                                                           <span className='text-success'> <i className="fa-brands fa-whatsapp"></i>WhatsApp Message</span>
                                                                        </span>
                                                                     </>
                                                                  )}

                                                                  {["created", "pending pickup", "picked", "arrived", "hold"].includes(item.status) && checkAllowedAction("Text-Message") && (
                                                                     <>
                                                                        <div className="dropdown-divider"></div>
                                                                        <span onClick={() => handleSendTextMsgModal(item)} className="dropdown-item">
                                                                           <i className="fa-regular fa-message-text"></i>Text Message
                                                                        </span>
                                                                     </>
                                                                  )}

                                                               </div>}
                                                         </div>
                                                      </div>
                                                   </td>
                                                </tr>)
                                             )}
                                          </tbody>
                                       </table>
                                    </div>
                                 </div>
                                 <div className="row pagination_new">
                                    <TableFooter currentPage={currentPage} itemsPerPage={itemsPerPage} resData={openOrders} totalCount={totalCount} />
                                    <div className="col-lg-4">
                                       <Pagination
                                          totalCount={totalCount}
                                          currentPage={currentPage}
                                          itemsPerPage={itemsPerPage}
                                          maxPageNumberLimit={maxPageNumberLimit}
                                          minPageNumberLimit={minPageNumberLimit}
                                          setcurrentPage={setcurrentPage}
                                          setmaxPageNumberLimit={setmaxPageNumberLimit}
                                          setminPageNumberLimit={setminPageNumberLimit}
                                       />
                                    </div>
                                 </div>
                              </React.Fragment>
                           }
                           {openOrders?.length === 0 && !isLoading && <NotFoundData />}
                        </div>
                     </div>
                  </div>
               </div>
            </section >
         </div>
         {assignModal && <AssignOrderModal
            loader={loader}
            modal={assignModal}
            orderDetails={orderDetails}
            closeModal={() => setAssignModal(false)}
            handleAssignOrder={handleAssignOrder}
         />}

         {assignWithMap && <AssignWithMapModal
            loader={loader}
            modal={assignWithMap}
            order={orderDetails}
            setLoading={setLoading}
            closeModal={() => setAssignWithMap(false)}
            handleAssignOrder={handleAssignOrder}
         />}

         {cancelModel && <ReasonModal
            loader={loader}
            modal={cancelModel}
            orderDetails={orderDetails}
            closeModal={() => setCancelModel(false)}
            handleSubmit={handleCancelOrder}
         />}

         {onHoldModal && <ReasonModal
            loader={loader}
            modal={onHoldModal}
            orderDetails={orderDetails}
            closeModal={() => setOnHoldModal(false)}
            handleSubmit={placeOnholdOrder}
         />}

         {exportModal && < ExportModel
            loader={fileLoading}
            exportModal={exportModal}
            totalCount={totalCount}
            toggleExportModal={toggleExportModal}
            selectedItem={selectedItem}
            getExportingData={getExportingData}
         />}

         {orderDetailModal && <OrderDetailsModel
            order_id={orderId}
            setLoading={setLoading}
            isLoading={isLoading}
            modal={orderDetailModal}
            closeModal={() => setOrderDetailModal(false)}
         />}

         {pushMsgModel && <PushMessageModal
            orderDetails={orderDetails}
            pushMsgModel={pushMsgModel}
            handlePushmsgModel={() => setPushMsgModel(false)}
         />}

         {reorderModel && <ReorderModal
            loader={loader}
            actionType={actionType}
            reorderModel={reorderModel}
            closeModal={() => setReorderModel(false)}
            handleReorderCreated={handleReorderCreated}
            handleReorderPendingPick={handleReorderPendingPick}
            handleRenotifyOrder={handleRenotifyOrder}
            createToScheduleOrder={createToScheduleOrder}
         />}

         {orderNotesModal && <OrderNotesModel
            orderDetails={orderDetails}
            setLoading={setLoading}
            isLoading={isLoading}
            modal={orderNotesModal}
            closeModal={() => setOrderNotesModal(false)}
         />}

         {editBuyerModel && <EditBuyerModal
            orderDetails={orderDetails}
            modal={editBuyerModel}
            closeModal={() => setEditBuyerModel(false)}
            handleUpdateBuyerOrder={handleUpdateBuyerOrder}
            isLoading={isLoading}
         />}

         {addCommentModal && <AddCommentModal
            orderDetails={orderDetails}
            modal={addCommentModal}
            closeModal={() => setAddCommentModal(false)}
            isLoading={isLoading}
            setLoading={setLoading}
            orderId={orderId}
         />}

         {viewCommentModal && <ViewComments
            modal={viewCommentModal}
            closeModal={() => setViewCommentModal(false)}
            isLoading={isLoading}
            setLoading={setLoading}
            orderId={orderId}
            orderDetails={orderDetails}
         />}

         {rightSlider &&
            < DriversRosterList
               rightSlider={rightSlider}
               closeSlider={() => setRightSlider(false)}
            />}

         {sendWhatsAppMsgModal &&
            < SendWhatsAppMessageModal
               orderDetails={orderDetails}
               modal={sendWhatsAppMsgModal}
               closeModal={() => setSendWhatsAppMsgModal(false)}
            />}

         {sendTextMsgModal &&
            < SendTextMessageModal
               orderDetails={orderDetails}
               modal={sendTextMsgModal}
               closeModal={() => setSendTextMsgModal(false)}
            />}


         {whatsAppMsgesModal &&
            < WhatsAppMsges
               orderDetails={orderDetails}
               modal={whatsAppMsgesModal}
               closeModal={() => setWhatsAppMsgesModal(false)}
            />
         }
      </>
   )
}
export default IsLoadingHOC(IsLoggedinHOC(OpenOrders))
