
import React, { useState, useEffect, useContext } from 'react';
import ExportModel from '../../Components/ConfirmModel/ExportData';
import OrderDetailsModel from '../../Components/DetailsModel/OrderDetails';
import OrderNotesModel from '../../Components/DetailsModel/OrderNotes';
import { getOrdersList } from '../../Redux/action/Orders';
import IsLoggedinHOC from '../../Components/IsLoggedinHOC';
import Pagination from '../../Components/Pagination';
import IsLoadingHOC from '../../Components/IsLoadingHOC';
import { useSelector } from 'react-redux';
import { dateFormat, exportToExcel, getBuyerLoaction, handleCopyClipboard, trimObjValues } from '../../Helper';
import SearchForm from '../../Components/SearchOrder';
import { CanceledHeader } from '../../utils/OrdersCSV';
import TableFooter from '../../Components/TableFooter';
import ViewComments from "../../Components/DetailsModel/ViewComments";
import { setNotifyOrder } from '../../Redux/action/App';
import WhatsAppMsges from '../../Components/DetailsModel/WhatsAppMsges';
import { SidebarContext } from '../../Context/SidebarContext';
import { OrderContext } from '../../Context/OrderContext';
import { Link } from 'react-router-dom';
import {
   checkWhatsAppMsg,
   handleSelectListItem,
   isAllItemSelected,
   isIntegratorNumber,
   isOrderNotes,
   isShopOrderNumber,
   showShopDirection
} from '../../Helper';
import NotFoundData from '../../Components/NotFoundData';
import { CSVLink } from 'react-csv';
import moment from 'moment';


function CanceledOrders(props) {
   const { isLoading, setLoading } = props
   const { user } = useSelector(state => state.auth)
   const { _id, type, allowed_order_export_column, drivers } = user ? user : {}
   const [canceledOrders, setCanceledOrders] = useState([])
   const [selectedValue, setSelectedValue] = useState({ shopid: null, driverid: null });
   const [selectedCancelReason, setSelectedCancelReason] = useState(null);
   const [sorting, setSorting] = useState({ order_in: -1, order_by: "canceldate" })
   const { branches_id } = useSelector(state => state.shop)
   const [selectedBranches, setSelectedBranches] = useState([])
   const { notifyOrder } = useSelector(state => state.app)


   let exportsColumns = []
   if (type === "Admin") {
      exportsColumns = CanceledHeader
   }
   else {
      const filteredArray = CanceledHeader.filter(item => allowed_order_export_column?.includes(item.label))
      exportsColumns = filteredArray || []
   }

   const {
      orderDetailModal,
      setOrderDetailModal,
      orderNotesModal,
      setOrderNotesModal,
      viewCommentModal,
      setViewCommentModal,
      whatsAppMsgesModal,
      setWhatsAppMsgesModal
   } = useContext(OrderContext)

   const {
      csvLink,
      dispatch,
      totalCount,
      toast,
      setTotalCount,
      currentPage,
      setcurrentPage,
      itemsPerPage,
      exportsData,
      setExportsData,
      selectedDate,
      setSelectedDate,
      selectedItem,
      handleSelectItem,
      setSelectedItem,
      fileLoading,
      resetForm,
      handleSubmit,
      setFileLoading,
      orderDetails,
      setOrderDetail,
      toggleExportModal,
      handleSorting,
      orderId,
      setOrderId,
      exportModal,
      maxPageNumberLimit,
      setmaxPageNumberLimit,
      minPageNumberLimit,
      setminPageNumberLimit,
   } = useContext(SidebarContext)


   const [searchParams, setSearchParams] = useState({
      status: "canceled",
      shopid: ["Admin", "Logistics"].includes(user.type) ? "" : branches_id,
      driverid: ["Logistics"].includes(user.type) ? drivers.join(',') : "",
      branch_ids: "",
      shopname: "",
      ordernumber: "",
      "order_notes.message": "",
      drivername: "",
      receiver_name: "",
      buyerphone: "",
      receiver_address: "",
      from_date: "",
      to_date: "",
   })

   useEffect(() => {
      if (notifyOrder?._id) {
         if (notifyOrder.status === 'canceled') {
            setCanceledOrders((prevOrders) => [notifyOrder, ...prevOrders]);
         }
         dispatch(setNotifyOrder(""))
      }
   }, [notifyOrder]);

   useEffect(() => {
      getCancelledOrdersData();
   }, [currentPage, sorting])


   const handleSelectShop = (option, name) => {
      let { value } = option ? option : {}
      if (value) {
         setSearchParams((prevState) => ({
            ...prevState,
            [name]: value,
         }));
      }
      else {
         if (user.type === "Admin") {
            setSearchParams((prevState) => ({
               ...prevState,
               [name]: "",
            }));
         }
         else {
            setSearchParams((prevState) => ({
               ...prevState,
               [name]: _id,
            }));
         }
      }
      setSelectedValue((prevState) => ({
         ...prevState,
         [name]: option,
      }));
   }

   const handleSelectDriver = (option, name) => {
      let { value } = option ? option : {}
      setSearchParams((prevState) => ({
         ...prevState,
         [name]: value || '',
      }));
      setSelectedValue((prevState) => ({
         ...prevState,
         [name]: option,
      }));
   }

   const handleSelectBranch = (options) => {
      const selectedValues = []
      options.map(item => selectedValues.push(item.value))
      setSearchParams((prevState) => ({
         ...prevState,
         branch_ids: selectedValues
      }));
      setSelectedBranches(options)
   }

   const handleSearch = (e) => {
      const { name, value } = e.target
      setSearchParams((prevState) => ({
         ...prevState,
         [name]: value,
      }));
   }

   const handleChangeDate = (date, name) => {
      setSearchParams((prevState) => ({
         ...prevState,
         [name]: date ? moment(date).format("YYYY-MM-DD") : ''
      }));
      setSelectedDate((prevState) => ({
         ...prevState,
         [name]: date,
      }));
   }

   const handleSelectCancelReason = (option, name) => {
      let { value } = option ? option : {}
      setSelectedCancelReason(option)
      setSearchParams((prevState) => ({
         ...prevState,
         [name]: value || '',
      }));
   }

   const getCancelledOrdersData = async () => {
      if (user.type === "Logistics" && (drivers?.length === 0)) {
         return;
      }
      const search_params = trimObjValues(searchParams)
      const payload = {
         search: search_params,
         shorting: sorting,
         pagination: { start: currentPage, length: itemsPerPage }
      }
      setLoading(true)
      await dispatch(getOrdersList(payload))
         .then(
            response => {
               setLoading(false);
               if (response.status === "1") {
                  setCanceledOrders(response.data.orders)
                  setTotalCount(response.data.total_count)
               } else {
                  setCanceledOrders([])
                  setTotalCount(null)
               }
            },
            (error) => {
               setLoading(false);
               toast.error(error.response.data?.message)
            }
         )
   }

   const getExportingData = async () => {
      try {
         if (user.type === "Logistics" && (drivers?.length === 0)) {
            return;
         }
         const totalItem = [];
         const search_params = trimObjValues(searchParams);
         const fetchData = async (start, length, selectedItems) => {
            const payload = {
               search: search_params,
               selected_items: selectedItems,
               shorting: sorting,
               pagination: { start, length }
            };
            setFileLoading(true);
            const response = await dispatch(getOrdersList(payload));
            setFileLoading(false);
            if (response.status === "1") {
               totalItem.push(...response.data.orders);
            } else {
               throw new Error(response.data?.message || "Failed to fetch data");
            }
         };

         if (selectedItem && selectedItem.length > 0) {
            await fetchData(1, 1000, selectedItem);
         } else {
            const batchSize = 50000;
            const numBatches = Math.ceil(totalCount / batchSize);
            const batchPromises = [];
            for (let i = 1; i <= numBatches; i++) {
               batchPromises.push(fetchData(i, batchSize, []));
            }
            await Promise.all(batchPromises);
         }

         // setExportsData(totalItem);

         const exportData = totalItem?.map((item) => {
            const exportItem = {};
            exportsColumns.forEach(header => {
               exportItem[header.label] = item[header.key];
            });
            return exportItem;
         });

         const fileName = `Canceled-Orders-${moment(new Date()).format("hh-mm-ss")}.xlsx`
         exportToExcel(exportData, fileName)

         // csvLink.current.link.click();
         toast.success("Data exported successfully");
         toggleExportModal();
      } catch (error) {
         setFileLoading(false);
         toast.error(error.message);
      }
   };

   const handleReset = async () => {
      const resetFormData = {
         status: "canceled",
         shopid: ["Admin", "Logistics"].includes(user.type) ? "" : branches_id,
         driverid: ["Logistics"].includes(user.type) ? drivers.join(',') : "",
         branch_ids: "",
         shopname: "",
         ordernumber: "",
         "order_notes.message": "",
         drivername: "",
         receiver_name: "",
         buyerphone: "",
         receiver_address: "",
         from_date: "",
         to_date: "",
      }
      setSearchParams(resetFormData)
      resetForm()
      setSelectedValue({ shopid: null, driverid: null })
      setSelectedCancelReason(null)
      setSorting({ order_in: -1, order_by: "canceldate" })
      setSelectedBranches([])
   }

   return (
      <>
         <div className="content-wrapper">
            <div className="content-header main_breadcrumb_top">
               <div className="container-fluid">
                  <div className="row mb-2">
                     <div className="col-sm-6">
                        <h1 className="m-0">Canceled Orders</h1>
                     </div>
                     <div className="col-sm-6">
                        <ol className="breadcrumb float-sm-right">
                           <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                           <li className="breadcrumb-item active">Orders</li>
                        </ol>
                     </div>
                  </div>
               </div>
            </div>
            <section className="content">
               <div className="container-fluid">
                  <div className="row">
                     <div className="col-lg-12">
                        <div className="all_drivers">
                           <div className="card card-dark">
                              <div className="card-header">
                                 <div className="container-fluid">
                                    <div className="row">
                                       <div className="col-6 col-lg-11">
                                          <h3 className="pl-5">Search</h3>
                                       </div>
                                       <div className="col-6 col-lg-1 d-flex justify-content-center align-items-center">
                                          <i className="fas fa-angle-up click_search"></i>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                              <form className="form_search" action="">
                                 <SearchForm
                                    searchParams={searchParams}
                                    selectedValue={selectedValue}
                                    selectedDate={selectedDate}
                                    handleSearch={handleSearch}
                                    handleSelectShop={handleSelectShop}
                                    handleSelectDriver={handleSelectDriver}
                                    handleChangeDate={handleChangeDate}
                                    selectedCancelReason={selectedCancelReason}
                                    handleSelectCancelReason={handleSelectCancelReason}
                                    handleSelectBranch={handleSelectBranch}
                                    selectedBranches={selectedBranches}
                                 />
                                 <div className="card-footer text-center">
                                    <button type="reset" className="btn btn-primary mr-2" onClick={handleReset} >Reset</button>
                                    <button type="button" onClick={() => handleSubmit(getCancelledOrdersData)} className="btn btn-success">Submit</button>
                                 </div>
                              </form>
                           </div>
                           <div className="row pagination_new">
                              <div className="col-lg-6 col-12">
                                 <button disabled={isLoading} type="button" onClick={() => handleSubmit(getCancelledOrdersData)} className="btn btn-secondary"><i className={`fa-regular fa-rotate-right ${isLoading && 'fa_spinner'}`}></i>Refresh</button>
                                 <button type="button" disabled={fileLoading || !totalCount || isLoading} onClick={toggleExportModal} className="btn btn-info ml-2"><i className="fa-regular fa-circle-down"></i>{fileLoading ? "Loading.." : "Export"}</button>
                                 {/* {type === 'Admin' ?
                                    <CSVLink className='csv-link' target='_blank' ref={csvLink}
                                       headers={CanceledHeader}
                                       data={exportsData}
                                       filename={`Canceled_Orders_${moment(new Date()).format("YYYY-MMM-DD_hh-mm-ss")}`}
                                    />
                                    :
                                    <CSVLink className='csv-link' target='_blank' ref={csvLink}
                                       headers={allowedExportColumn && allowedExportColumn.length > 0 ? allowedExportColumn : CanceledHeader}
                                       data={exportsData}
                                       filename={`Canceled_Orders_${moment(new Date()).format("YYYY-MMM-DD_hh-mm-ss")}`}
                                    />
                                 } */}
                              </div>
                              {canceledOrders?.length > 0 &&
                                 <div className="col-lg-6 col-12">
                                    <Pagination
                                       totalCount={totalCount}
                                       currentPage={currentPage}
                                       itemsPerPage={itemsPerPage}
                                       maxPageNumberLimit={maxPageNumberLimit}
                                       minPageNumberLimit={minPageNumberLimit}
                                       setcurrentPage={setcurrentPage}
                                       setmaxPageNumberLimit={setmaxPageNumberLimit}
                                       setminPageNumberLimit={setminPageNumberLimit}
                                    />
                                 </div>
                              }
                           </div>
                           {canceledOrders?.length > 0 &&
                              <React.Fragment>
                                 <div className="card table_card completed_1">
                                    <div className="card-body all_drive_table">
                                       <table id="example2" className="table table-hover tabele-imag">
                                          <thead>
                                             <tr>
                                                <th><input type="checkbox" id="check" checked={isAllItemSelected(canceledOrders, selectedItem)} onChange={(event) => handleSelectListItem(event, canceledOrders, selectedItem, setSelectedItem)} className="user_table_checkbox" /></th>
                                                <th>More info</th>
                                                <th>Order</th>
                                                <th>Shop</th>
                                                <th>Buyer</th>
                                                <th>Driver</th>
                                                <th>Created At</th>
                                                <th>Canceled At
                                                   {sorting.order_by === "canceldate" ?
                                                      <i onClick={() => handleSorting("canceldate")}
                                                         className={`fas ${sorting.order_in === 1 ? "fa-sort-amount-down-alt" : "fa-sort-amount-down"}`}>
                                                      </i>
                                                      : <i onClick={() => handleSorting("canceldate")} className="fa-solid fa-arrow-down-arrow-up"></i>
                                                   }
                                                </th>
                                             </tr>
                                          </thead>
                                          <tbody>
                                             {canceledOrders.map((item, i) => (
                                                <tr key={item._id} className="accordion-toggle" style={{ backgroundColor: item?.location_shared === true && '#e8eddf' }}>
                                                   <td><input type="checkbox" className="table_checkbox"
                                                      onChange={(event) => handleSelectItem(event, item._id)}
                                                      checked={selectedItem.includes(item._id)} /></td>
                                                   <td>
                                                      <div title='Order-Info'
                                                         onClick={() => {
                                                            setOrderId(item._id)
                                                            setOrderDetailModal(true)
                                                         }} >
                                                         <i className="fa-solid fa-circle-info fa-lg"></i></div>
                                                      {
                                                         item.have_operation_comments &&
                                                         <div title='View-comments'
                                                            onClick={() => {
                                                               setOrderId(item._id)
                                                               setOrderDetail(item)
                                                               setViewCommentModal(true)
                                                            }}
                                                         ><i className="fa-solid fa-comment fa-lg"></i>
                                                         </div>
                                                      }
                                                      {
                                                         isOrderNotes(item.order_notes) &&
                                                         <div title='show notes order'
                                                            onClick={() => {
                                                               setOrderDetail(item)
                                                               setOrderNotesModal(true)
                                                            }}>
                                                            <i className="fa-solid fa-note fa-lg"></i>
                                                         </div>
                                                      }
                                                      {
                                                         checkWhatsAppMsg(item.order_history) &&
                                                         <div title='View-message'
                                                            onClick={() => {
                                                               setOrderId(item._id)
                                                               setOrderDetail(item)
                                                               setWhatsAppMsgesModal(true)
                                                            }}>
                                                            <i className="fa-brands fa-whatsapp text-success fa-lg"></i>
                                                         </div>
                                                      }
                                                   </td>
                                                   <td>
                                                      <div onClick={() => handleCopyClipboard(item._id)} className='cursor-pointer' title='Order-number'>
                                                         {item.requested_vehicle_type === "bike" && <i className="fa-solid fa-motorcycle"></i>}
                                                         {item.requested_vehicle_type === "car" && <i className="fa-solid fa-car"></i>}
                                                         {item.requested_vehicle_type === "truck" && <i className="fa-sharp fa-solid fa-truck"></i>}
                                                         {item.requested_vehicle_type === "van" && <i className="fa-solid fa-van-shuttle"></i>}
                                                         {item.requested_vehicle_type === "all" && <i className="fa-sharp fa-solid fa-truck"></i>}
                                                         {item.ordernumber}
                                                      </div>
                                                      {isIntegratorNumber(item?.integrator_number)}
                                                      {isShopOrderNumber(item?.shop_ordernumber)}
                                                      <div className='text-success' role="button" title='Order-Price'><i className="fa-solid fa-coins"></i>{item?.orderprice}</div>
                                                      <div className='text-capitalize text-secondary' role="button" title='Payment-Method'><i className="fa-duotone fa-credit-card"></i>{item?.paymentmethod}</div>
                                                   </td>
                                                   <td>
                                                      <div title={item.shopname}><i className="fa-solid fa-store"></i>{item.shopname}</div>
                                                      {item?.senderphone && <div title={item.senderphone}><i className="fa fa-phone" aria-hidden="true"></i>{item?.senderphone}</div>}
                                                      <div className='text-capitalize'><i className="fa-solid fa-diamond-turn-right"></i>{showShopDirection(item)}</div>
                                                   </td>
                                                   <td>
                                                      <div title={item.receiver_name}><i className="fa fa-user" aria-hidden="true"></i>{item.receiver_name}</div>
                                                      <div><i className="fa fa-phone" aria-hidden="true"></i>{item.buyerphone ? item.buyerphone : "Not Available"}</div>
                                                      <div title={getBuyerLoaction(item, 'unlimited')}>
                                                         <i className="fa-solid fa-location-dot"></i>
                                                         {getBuyerLoaction(item, 'limited')}
                                                      </div>
                                                   </td>
                                                   <td>
                                                      <div title={item.drivername}><i className="fa fa-user" aria-hidden="true"></i>{item.drivername}</div>
                                                      <div> <i className="fa fa-phone" aria-hidden="true"></i>{item.driverphone ? item.driverphone : "Not Available"}</div>
                                                      {item.driver?.length > 0 && item.driver[0]?.job_directions && item.driver[0]?.job_directions.length > 0 && <div>
                                                         <span className='text-capitalize'>
                                                            <i className="fa-solid fa-diamond-turn-right"></i>
                                                            {item.driver[0]?.job_directions?.join(' , ')}
                                                         </span>
                                                      </div>
                                                      }
                                                   </td>
                                                   <td>{dateFormat(item._created_at)}</td>
                                                   <td>{dateFormat(item.canceldate)}</td>
                                                </tr>)
                                             )}
                                          </tbody>
                                       </table>
                                    </div>
                                 </div>
                                 <div className="row pagination_new">
                                    <TableFooter currentPage={currentPage} itemsPerPage={itemsPerPage} resData={canceledOrders} totalCount={totalCount} />
                                    <div className="col-lg-4">
                                       <Pagination
                                          totalCount={totalCount}
                                          currentPage={currentPage}
                                          itemsPerPage={itemsPerPage}
                                          maxPageNumberLimit={maxPageNumberLimit}
                                          minPageNumberLimit={minPageNumberLimit}
                                          setcurrentPage={setcurrentPage}
                                          setmaxPageNumberLimit={setmaxPageNumberLimit}
                                          setminPageNumberLimit={setminPageNumberLimit}
                                       />
                                    </div>
                                 </div>
                              </React.Fragment>
                           }
                           {canceledOrders?.length === 0 && !isLoading && <NotFoundData />}
                        </div>
                     </div>
                  </div>
               </div>
            </section>
         </div>
         {exportModal && <ExportModel
            loader={fileLoading}
            exportModal={exportModal}
            toggleExportModal={toggleExportModal}
            getExportingData={getExportingData}
            selectedItem={selectedItem}
            totalCount={totalCount}
         />}
         {orderDetailModal && <OrderDetailsModel
            order_id={orderId}
            setLoading={setLoading}
            isLoading={isLoading}
            modal={orderDetailModal}
            closeModal={() => setOrderDetailModal(false)}
         />}
         {orderNotesModal && <OrderNotesModel
            orderDetails={orderDetails}
            setLoading={setLoading}
            isLoading={isLoading}
            modal={orderNotesModal}
            closeModal={() => setOrderNotesModal(false)}
         />}
         {viewCommentModal && <ViewComments
            orderDetails={orderDetails}
            modal={viewCommentModal}
            closeModal={() => setViewCommentModal(false)}
            isLoading={isLoading}
            setLoading={setLoading}
            orderId={orderId}
         />}
         {whatsAppMsgesModal &&
            < WhatsAppMsges
               orderDetails={orderDetails}
               modal={whatsAppMsgesModal}
               closeModal={() => setWhatsAppMsgesModal(false)}
            />
         }
      </>
   )
}
export default IsLoadingHOC(IsLoggedinHOC(CanceledOrders))