import React, { useEffect, useState } from 'react';
import { addCommentApi, getCommentsApi, removeCommentApi, updateCommentApi } from '../../Redux/action/App';
import { useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { formatChatTimestamp } from '../../Helper';


function AddCommentsModal(props) {
    const { modal, closeModal, orderId, setLoading, isLoading, orderDetails } = props
    const dispatch = useDispatch()
    const [allComments, setAllComments] = useState([])
    const [editAble, setEditAble] = useState(false)
    const [commentId, setCommentId] = useState('')


    useEffect(() => {
        getCommentsList();
    }, [])


    const getCommentsList = async () => {
        const payload = {
            search: {
                order_id: orderId
            },
            shorting: {
                order_in: 1,
                order_by: "_created_at"
            },
            pagination: {
                start: 1,
                length: 100
            }
        }
        setLoading(true)
        await dispatch(getCommentsApi(payload))
            .then(
                response => {
                    setLoading(false);
                    if (response.status === "1") {
                        const resData = response.data?.webhooks
                        setAllComments(resData)
                    }
                },
                (error) => {
                    setLoading(false);
                    toast.error(error.response.data?.message)
                })
            .catch(error => console.log(error))
    }

    const formik = useFormik({
        initialValues: {
            message: '',
            order_id: orderId
        },
        validationSchema: Yup.object({
            message: Yup.string()
                .required("This field is required")
        }),
        onSubmit: async values => {
            const addPayload = { message: values.message, order_id: orderId }
            const editPayload = { message: values.message, comment_id: commentId }

            setLoading(true)
            await dispatch(editAble ? updateCommentApi(editPayload) : addCommentApi(addPayload))
                .then(
                    response => {
                        setLoading(false)
                        if (response.status == '1') {
                            toast.success(response.data.message)
                            formik.resetForm();
                            setEditAble(false)
                            getCommentsList()
                            // closeModal();
                        } else {
                            toast.error(response.data?.message)
                        }
                    },
                    error => {
                        setLoading(false)
                        toast.error(error.response.data?.message)
                    }
                )
        }
    });

    const handleDeleteComment = async (_id) => {
        const payload = { comment_id: _id }
        setLoading(true)
        await dispatch(removeCommentApi(payload))
            .then(
                response => {
                    setLoading(false)
                    if (response.status == '1') {
                        setEditAble(false)
                        formik.resetForm();
                        getCommentsList()
                    } else {
                        toast.error(response.data?.message)
                    }
                },
                error => {
                    setLoading(false)
                    toast.error(error.response.data?.message)
                }
            )
    }

    const handleEdit = (item) => {
        formik.setFieldValue('message', item.message)
        setCommentId(item._id)
        setEditAble(true)
    }

    return (
        <div id="myModal" className={`modal fade cs--modal scroler-fixed ${modal ? "show" : "hide"}`} style={{ display: modal ? "block" : "none" }}
            role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
            <div className="modal-dialog modal-lg" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title w-100 text-center text-secondary" id="exampleModalLongTitle">Comments {`(${orderDetails?.ordernumber})`}</h5>
                        <button onClick={() => closeModal()} type="button"
                            className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div style={{ maxHeight: '400px', overflowY: 'auto' }} className="modal-body">
                        <div className="comment-widgets">
                            {allComments && allComments.length > 0 &&
                                allComments.map(item =>
                                    <div key={item._id} className="d-flex flex-row comment-row">
                                        <div className="p-2">
                                            <img src={require("../../assets/dist/img/dummy-profile-image.png")} alt="user" className="rounded-circle" />
                                        </div>
                                        <div className="comment-text w-100">
                                            <div className='d-flex justify-content-between'>
                                                <h5 className="font-medium text-capitalize">{item.user && item?.user[0].username}</h5>
                                                <span type="button" className="dropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABwAAAAcCAYAAAByDd+UAAAACXBIWXMAAAsSAAALEgHS3X78AAABKklEQVRIiWP8//8/AwyELr7lwMDAkMDAwBDAwMDAz0AZeMjAwHCAgYFhwepYtQMwk+AWhi6+NYGBgSGfQktwgYmrY9UKQHJMdLAMBPKhdjAwhiy6CQrG/TS0DBk4MkHjjF4gAWShAx0tdABZKE9HC+WZyNGlxMcOxuQAki0EWVTvKQvG5FhKsoViXCwMXGxMYAxikwpI1nHixVeG6Qeew9k0txAE9j3+TI42MBhNNFQHoLL0P6mGwnx279NPkt1DVqIhxyIYICtInWR5wZgcQLIPLSS4GTIdJMHsb7v/kZwXSbbw1bc/DN9+/YOzSQUjI9F8JFs36eAhyMINdLTwAMjCBXS0cAETtJE6kQ6WgdqmYB8yQBuptLQU3hDG1dQH0ZQ2rjCb+gwMDAD9n2U0tylYpwAAAABJRU5ErkJggg==" alt="action-btn" />
                                                </span>
                                                <div className="dropdown-menu">
                                                    <span role="button" className="dropdown-item text-danger cursor-pointer" onClick={() => handleDeleteComment(item._id)}  ><i className="fa-regular fa-trash-can-xmark mr-2"></i><span>Remove</span></span>
                                                    <div className="dropdown-divider"></div>
                                                    <span role="button" className="dropdown-item text-primary cursor-pointer" onClick={() => handleEdit(item)} ><i className="fa-regular fa-pen-to-square mr-2"></i>Edit</span>
                                                </div>
                                            </div>
                                            <div>
                                                <p className="m-b-15 d-block">{item.message}</p>
                                            </div>
                                            <div className="comment-footer">
                                                <small className="text-muted float-right">{formatChatTimestamp(item._created_at)}</small>
                                            </div>
                                        </div>
                                    </div>
                                )}
                        </div>
                        {allComments && allComments.length === 0 && !isLoading &&
                            <div className='text-center p-4'>
                                <h5>No records available...</h5>
                            </div>
                        }
                    </div>
                    <div style={{ display: "block" }} className="modal-footer">
                        <div className="form-group required">
                            <label className="control-label">Add comment</label>
                            <textarea type="text" className="form-control" placeholder="Enter here"
                                required
                                name='message'
                                value={formik.values.message}
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                            />
                            {formik.touched.message && formik.errors.message ? (<span className="text-danger">{formik.errors.message}</span>) : null}
                        </div>
                        <div className='pt-2 text-right'>
                            <button onClick={formik.handleSubmit} type="button" className="btn btn-success">{editAble ? 'Update' : 'Save'}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default AddCommentsModal