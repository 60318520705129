import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { getdriverOrdersRoadmap } from '../../Redux/action/User';
import { getOnlyTime, setStatusColor } from '../../Helper';

function DriverOrders(props) {
    const { modal, closeModal, driverDetails, setLoading, isLoading } = props;
    const { _id, username } = driverDetails;
    const [listData, setListData] = useState([]);
    const [mode, setMode] = useState("default");
    const dispatch = useDispatch();

    useEffect(() => {
        getDriverOrdersMap();
    }, [mode]);


    const getDriverOrdersMap = async () => {
        const payload = { user_id: _id, mode: mode };
        setLoading(true);
        await dispatch(getdriverOrdersRoadmap(payload))
            .then(
                response => {
                    setLoading(false);
                    if (response.status === 1) {
                        const resData = response.data.order;
                        console.log("resData", resData);
                        setListData(resData);
                    }
                },
                (error) => {
                    setLoading(false);
                    toast.error(error.response.data?.message);
                })
            .catch(error => console.log(error));
    };

    return (
        <div id="myModal" className={`modal fade cs--modal ${modal ? "show" : "hide"}`} style={{ display: modal ? "block" : "none" }}>
            <div className="modal-dialog modal-lg">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title w-100 text-center text-info">{username}'s Road Map</h5>
                        <button onClick={() => closeModal()} type="button" className="close" data-dismiss="modal" aria-hidden="true">&times;</button>
                    </div>
                    <div className="modal-body overflow-auto modal--body--scroll">
                        <div className="row d-flex justify-content-center">
                            <div className="col-md-12">
                                <div className="mb-3 text-center">
                                    <div className="btn-group btn-group-toggle" data-toggle="buttons">
                                        <label className={`btn btn-outline-secondary ${mode === "default" ? "active" : ""}`}>
                                            <input type="radio" name="mode" value="default" checked={mode === "default"} onChange={(e) => setMode(e.target.value)} /> Default Mode
                                        </label>
                                        <label className={`btn btn-outline-secondary ${mode === "delivery" ? "active" : ""}`}>
                                            <input type="radio" name="mode" value="delivery" checked={mode === "delivery"} onChange={(e) => setMode(e.target.value)} /> Delivery Mode
                                        </label>
                                    </div>
                                </div>
                                <div className="main-card card time--line--card">
                                    {listData?.length > 0 &&
                                        <div className="card-body">
                                            <h5 className="">Driver Assign Orders</h5>
                                            <div className="vertical-timeline vertical-timeline--animate vertical-timeline--one-column">
                                                {listData.map((item, i) =>
                                                    <div key={i + 1} className="vertical-timeline-item vertical-timeline-element">
                                                        <div>
                                                            <span className="vertical-timeline-element-icon bounce-in">
                                                                <i className="badge badge-dot badge-dot-xl badge-secondary"></i>
                                                            </span>
                                                            <div className="vertical-timeline-element-content bounce-in d-flex justify-content-between">
                                                                <div>
                                                                    <div className='d-flex'>
                                                                        <h4 className="timeline-title">{item.ordernumber}</h4>
                                                                        <span style={setStatusColor(item.status)} className="timeline--stamp text-capitalize">{item.status}</span>
                                                                        <span className='timeline--stamp pl-1'>{`(${item.order_distance} km)`}</span>
                                                                    </div>
                                                                    {mode === "default" ?
                                                                        <div className='d-flex'>
                                                                            <p style={{ fontSize: '14px' }}>
                                                                                <i className="fa-duotone fa-shop pr-2"></i>
                                                                                {item.sender_full_address}
                                                                            </p>
                                                                        </div>
                                                                        :
                                                                        <div className='d-flex'>
                                                                            <p style={{ fontSize: '14px' }}>
                                                                                <i className="fa-solid fa-circle-user fa-lg pr-2"></i>
                                                                                {item.receiver_full_address}
                                                                            </p>
                                                                        </div>
                                                                    }
                                                                    <span className="vertical-timeline-element-date">{getOnlyTime(item._created_at)}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    }
                                </div>
                                {listData?.length < 1 && !isLoading &&
                                    <div className='d-flex justify-content-center align-items-center text-center pt-3 pb-3'>
                                        <h5>No records available..</h5>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" onClick={() => closeModal()} className="btn btn-secondary" data-dismiss="modal">Close</button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default DriverOrders;
